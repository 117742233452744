import { Mesh } from 'three';
import { VglObject3dWithMatarialAndGeometry } from 'vue-gl/src/mixins';
import { name, names } from 'vue-gl/src/types';
import { validateName, validateNames } from 'vue-gl/src/validators';

export default {
  mixins: [VglObject3dWithMatarialAndGeometry],
  props: {
    /** Name of the geometry, defining the object's structure. */
    geometry: { type: name, validator: validateName },
    /**
     * A Material name or an array of Material name, defining the object's appearance.
     *
     * A single material will apply the material to all object's faces meanwhile
     * an array of material will apply each material to the matching index object's face
     */
    material: { type: names, validator: validateNames },
  },
  data: () => ({
    objUuid: null,
  }),
  computed: {
    inst() {
      return this.objUuid ? this.obj : new Mesh();
    },
    // eslint-disable-next-line
    loader() {
      throw new Error(`Class ${typeof this} needs to add a loader`);
    }
  },
  watch: {
    src: {
      handler(src) {
        this.loader.load(src, (obj) => {
          const mesh = obj.children.pop();
          this.obj = mesh;
          this.objUuid = mesh.uuid;
        });
      },
      immediate: true,
    },
  },
};